import React from "react";
import { Link } from "gatsby";
import Style from "styles/components/entry_button.module.scss";

const EntryButton = () => {
  return (
    <p className={Style.entryButton}>
      <Link className={Style.entryButton__link} to="/contact/">
        応募する
      </Link>
    </p>
  );
};

export default EntryButton;
