import React from "react";
import { Link } from "gatsby";
import ClassNames from "classnames";
import Style from "styles/components/link_button.module.scss";

const LinkButton = ({ color, url, text }) => {
  const whiteButton = ClassNames([Style.link], [Style.link__white]);
  const blackButton = ClassNames([Style.link], [Style.link__black]);

  const classDict = {
    white: whiteButton,
    black: blackButton,
  };

  return (
    <Link to={url} className={color in classDict ? classDict[color] : blackButton}>
      {text}
    </Link>
  );
};

export default LinkButton;
